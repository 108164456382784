import React from 'react'
import NavigationBar from '../Nav'

export default (
  { subNav, language, changeLanguage, location, history },
  ...props
) => (
  <div>
    <NavigationBar
      subNav={subNav}
      language={language}
      changeLanguage={changeLanguage}
      {...location}
    />
  </div>
)
