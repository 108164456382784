import React, { Fragment, useState } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Meta from './Meta'
import Nav from './Nav'

import Footer from './Footer'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'

import './../../../static/font/Raleway-SemiBold.ttf'
import './../../../static/font/Raleway-Bold.ttf'

import 'modern-normalize/modern-normalize.css'
import './globalStyles.css'
import './../Grid/grid.css'

export default ({ children, meta, title, metaCrumb, sClick }, props) => {
  const url = typeof window !== 'undefined' ? window.location.href : ''
  let en = new RegExp('/en')
  let metaCrumbId = 1
  const [language, setLanguage] = useState(en.test(url) ? 'en' : 'cs')
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            googleTrackingId
            socialMediaCard {
              image
            }
          }
          allPosts: allMarkdownRemark(
            filter: { fields: { contentType: { eq: "postCategories" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { siteTitle, socialMediaCard, googleTrackingId } =
            data.settingsYaml || {},
          subNav = {
            posts: data.allPosts.hasOwnProperty('edges')
              ? data.allPosts.edges.map(post => {
                  return { ...post.node.fields, ...post.node.frontmatter }
                })
              : false
          }

        const changeLanguage = () => {
          switch (language) {
            case 'cs':
              setLanguage('en')
              break

            case 'en':
              setLanguage('cs')
              break

            default:
              break
          }
        }

        return (
          <Fragment>
            <Helmet defaultTitle={siteTitle} titleTemplate={`%s ${siteTitle}`}>
              {title}
              <html lang="cs" />
              <link
                href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700&display=swap"
                rel="stylesheet"
              ></link>
              {metaCrumb && (
                <script type="application/ld+json">
                  {`{ "@context":"http://schema.org",
                      "@type":"BreadcrumbList",
                      "itemListElement": [${metaCrumb.map(crumb => {
                        return `{
                      '@type': 'ListItem',
                      "position": ${metaCrumbId++},
                      "item": {
                        '@id': "https://ekp.cz${crumb.pathname}",
                        "name": '${crumb.crumbLabel}'
                      }}`
                      })}]}`}
                </script>
              )}
            </Helmet>
            <Meta
              googleTrackingId={googleTrackingId}
              absoluteImageUrl={
                socialMediaCard &&
                socialMediaCard.image &&
                socialMediaCard.image
              }
              sClick={sClick}
              {...meta}
              {...data.settingsYaml}
            />
            <ToastContainer />

            <Nav
              subNav={subNav}
              language={language}
              changeLanguage={changeLanguage}
            />
            <Fragment>
              <div style={{ marginTop: '8rem' }}>{children}</div>
            </Fragment>
            <Footer />
          </Fragment>
        )
      }}
    />
  )
}
