import React, { useState } from 'react'
import './styles.css'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Menu, X } from 'react-feather'

const NavigationBarPreview = ({ logo }) => {
  const [active, setActive] = useState(false)
  return (
    <header>
      <div className="container-Nav">
        <nav className={active ? 'active' : ''}>
          <div onClick={() => setActive(!active)} className="menu-icons">
            <X className="ion-md-close" />
            <Menu className="ion-md-menu" />
          </div>
          <Link to="/">
            <img className="logo" src={logo.fluid.src} alt="logo" />
          </Link>
          <ul className="nav-list">
            <li className="move-left">
              <div className="btn">
                <a
                  className="black-button"
                  href="mailto:&#101;&#107;&#112;&#64;&#101;&#107;&#112;&#46;&#99;&#122;"
                >
                  &#101;&#107;&#112;&#64;&#101;&#107;&#112;&#46;&#99;&#122;
                </a>
              </div>

              <div className="btn">
                <a className="black-button" href="tel:+420273139330">
                  +420 273 139 330
                </a>
              </div>
            </li>
            <li>
              <Link className="uppercase">Převodní ceny</Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/co-jsou-prevodni-ceny">Co jsou převodní ceny</Link>
                </li>
                <li>
                  <Link to="/proc-venovat-prevodnim-cenam-pozornost">
                    Proč věnovat převodním cenám pozornost
                  </Link>
                </li>
                <li>
                  <Link to="/metody-pro-stanoveni">Metody pro stanovení</Link>
                </li>
                <li>
                  <Link to="/slovnik-prevodnich-cen">
                    Slovník převodních cen
                  </Link>
                </li>
                <li>
                  <Link to="/ukazky-prevodnich-cen">
                    Ukázky dokumentace převodních cen
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link className="uppercase" to="/">
                O nás
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/">Reference</Link>
                </li>
                <li>
                  <Link to="/">Články a Publikace </Link>
                </li>
                <li>
                  <Link to="/">Školení</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link className="uppercase" to="/">
                Judikatura
              </Link>
            </li>
            <li>
              <Link className="uppercase" to="/kontaktujte-nas">
                Kontaktujte nás
              </Link>
            </li>
            <li>
              <div className="move-top">
                <div className="btn-small">
                  <a
                    className="black-button"
                    href="mailto:&#101;&#107;&#112;&#64;&#101;&#107;&#112;&#46;&#99;&#122;"
                  >
                    &#101;&#107;&#112;&#64;&#101;&#107;&#112;&#46;&#99;&#122;
                  </a>
                </div>
              </div>
              <div className="move-top">
                <div className="btn-small">
                  <a className="black-button" href="tel:+420273139330">
                    +420 273 139 330
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

const NavigationBar = ({ location }) => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "hp-logo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            src
          }
        }
      }
    }
  `)
  return (
    <NavigationBarPreview logo={logo.childImageSharp} location={location} />
  )
}

export default NavigationBar
